import React from 'react'
import { useContext } from 'react'
import { GeoContext } from '@/context/geoContext'
import { DSText } from '@zoe/ds-web'
import { getCompanyNameByCountry } from '@/lib/utils/countryValues'

export const Copyright: React.FC = () => {
  const { country } = useContext(GeoContext)
  const year = new Date().getFullYear()

  return (
    <DSText as="span" variant="fluid-label-100" colour="primary-inverse">
      &copy;{year} {getCompanyNameByCountry(country)}
    </DSText>
  )
}
