import React, { useState } from 'react'
import styled from 'styled-components'
import * as Sentry from '@sentry/nextjs'

import { NUTRITION_NEWSLETTER_EMAIL_SUBMITTED } from '@/lib/braze/brazeConstants'
import { registerBrazeUserWithEmail } from '@/lib/braze/brazeUtils'
import { trackSignupEvent } from '@/lib/gtm/gtmV2'
import { EmailListType, LinkPosition, Section } from '@/lib/gtm/typesV2'
import { useCheckoutCountry } from '@/lib/hooks/useCheckoutCountry'
import { useCookies } from 'react-cookie'
import { QUIZ_ID_COOKIE_NAME } from '@/lib/checkoutConstants'
import { DSSpacer, DSText, DSTextAndIcon } from '@zoe/ds-web'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { Button } from 'react-aria-components'
import { useKameleoonApi } from '@/lib/kamaleoon/useKameleoonApi'
import { GOALS } from '@/lib/kamaleoon'

const StyledForm = styled(Form)`
  width: 100%;
  padding: 11px 10px 11px 16px;
  border-radius: var(--radius-8);
  border: 1px solid var(--colour-border-input);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledField = styled(Field)`
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  color: var(--colour-text-primary-inverse);
  font-family: var(--font-sans-serif-light);
  font-size: var(--font-size-input);
  margin-top: 4px;

  &::placeholder {
    color: var(--colour-text-primary-inverse);
  }
`

const StyledButton = styled(Button)`
  border: 0;
  border-radius: var(--radius-8);
  transition: background-color 0.1s ease-in-out;
  background-color: var(--colour-surface-neutral);
  color: var(--colour-text-button-primary);
  padding: var(--grid-8) var(--grid-16);

  &[data-hovered] {
    cursor: pointer;
  }

  &[data-disabled] {
    opacity: 0.5;
  }
`

const validationSchema = Yup.object({
  email: Yup.string().email('Email address does not look right').required('Please fill in your email'),
})

export const FooterNewsletterSignup = () => {
  const { processGoalConversion } = useKameleoonApi()
  const [submitSuccess, setSubmitSuccess] = useState<boolean | undefined>()
  const [cookies] = useCookies([QUIZ_ID_COOKIE_NAME])
  const { isReady, geoLocCountry } = useCheckoutCountry()

  const handleSubmit = async (values: any) => {
    const marketingConsent = true

    const additionalFields = {
      ...(cookies.cUserId && { quizUserId: cookies.cUserId }),
      ...(isReady && geoLocCountry && { country: geoLocCountry }),
    }

    const email = values.email
    const section: Section = 'footer'
    const emailList: EmailListType = 'newsletter'
    const linkPosition: LinkPosition = 'footer'
    if (email) {
      try {
        await registerBrazeUserWithEmail(
          values.email,
          NUTRITION_NEWSLETTER_EMAIL_SUBMITTED,
          marketingConsent,
          Object.keys(additionalFields).length !== 0 ? additionalFields : undefined,
        )
        setSubmitSuccess(true)
        trackSignupEvent({ section, emailList, email, linkPosition })
        processGoalConversion(GOALS.HOMEPAGE_EMAIL_SUBMITTED)
      } catch (error) {
        Sentry.captureException(error)
        console.error('Error registering user with Braze', error)
        setSubmitSuccess(false)
      }
    }
  }

  return (
    <>
      <DSText as="h3" variant="fluid-heading-600" colour="primary-inverse">
        Stay up to date with ZOE
      </DSText>
      <DSSpacer direction="vertical" size={16} />

      <Formik
        initialValues={{ email: '', marketingConsent: false }}
        onSubmit={handleSubmit}
        validateOnChange={true}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, isValid, touched }) => (
          <>
            <StyledForm>
              <StyledField id="email" name="email" type="email" placeholder="Enter your email" />
              <>
                <DSSpacer direction="horizontal" size={12} />
                <StyledButton type="submit" isDisabled={!isValid || isSubmitting || !touched}>
                  <DSText as="span" variant="fluid-paragraph-200" weight="lotaSemiBold" colour="primary">
                    Submit
                  </DSText>
                </StyledButton>
              </>
            </StyledForm>
            <DSSpacer direction="vertical" size={16} />
            {submitSuccess === false && (
              <DSTextAndIcon
                text="There has been an error. Please try again."
                textColour="primary-inverse"
                textVariant="fluid-paragraph-200"
                iconSize={16}
                iconPosition="left"
                iconColour="inverse"
                textWeight="lotaRegular"
                iconName="close"
              />
            )}
            {submitSuccess && (
              <DSTextAndIcon
                text="Thank you for signing up!"
                textColour="primary-inverse"
                textVariant="fluid-paragraph-200"
                iconSize={16}
                iconPosition="left"
                iconColour="inverse"
                textWeight="lotaRegular"
                iconName="tick-in-circle-no-fill"
              />
            )}
            {submitSuccess === undefined && (
              <DSText as="p" variant="fluid-label-100" weight="lotaLight" colour="primary-inverse">
                You&apos;ll receive our ongoing science and nutrition emails, plus news and offers.
              </DSText>
            )}
          </>
        )}
      </Formik>
      <DSSpacer direction="vertical" size={32} />
    </>
  )
}
